
import VueRecaptcha from "vue-recaptcha";
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import comunas from "@/helpers/comunas.json";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

interface agrupacionObj { 
  label: string
}
interface ticketData {
  rut: string;
  name: string;
  father_last_name: string;
  mother_last_name: string;
  document_type: string;
  document_id: string;
  street: string;
  street_number: string;
  district: {
    value: number,
    label: string
  };
  subrogante: {
    rut: string | null;
    name: string | null;
    father_last_name: string | null;
    mother_last_name: string | null;
    document_type?: string | null;
    document_id: string | null;
  };
  phone: number | null;
  agrupacion: agrupacionObj| null;
  nueva_agrupacion: string | null;
  rubro: string | null;
  nombre_fantasia: string| null;
  instagram: string | null;
  email: string;
  message: string;
  acepta_condiciones: boolean;
  ubicacion: {
    label: string,
    mounting: string,
    start_time: string
  }
}
@Component({
  components: {
    FilePond,
    VueRecaptcha
  }
})
export default class SendFeriaTicketForm extends Mixins(FormValidator) {
  protected formData: ticketData = {
    rut: "",
    name: "",
    father_last_name: "",
    mother_last_name: "",
    document_type: "1",
    document_id: "",
    street: "",
    street_number: "",
    district: {
      value: 108,
      label: "La Reina"
    },
    phone: null,
    email: "",
    message: "",
    agrupacion: { label: "" },
    nueva_agrupacion: "",
    rubro: "",
    instagram: "",
    acepta_condiciones: false,
    nombre_fantasia: "",
    subrogante: {
      rut: "",
      name: "",
      father_last_name: "",
      mother_last_name: "",
      document_type: "1",
      document_id: ""
    },
    ubicacion: {
      label: "",
      mounting: "",
      start_time: ""
    }
  };
  otraAgrupacion: boolean = false;
  options_street: any = [];
  options_district: any = comunas;
  street_loading = false;
  street_search_empty = false;
  disable_button = false;
  form_type = 0;
  options_agrupacion = [
    { label:"A.C.E La Reina Agrupación de Comerciantes Eventuales de La Reina" },
    { label:"Agrupación Artesanal y Emprendedores el Pueblito de La Reina"},
    { label:"Agrupación Comerciantes Artesanos de La Reina"},
    { label:"Agrupación Comerciantes Artesanos de La Reina"},
    { label:"Agrupación de Artesanos Villa La Reina"},
    { label:"Agrupación de Artesanos y Emprendedores de La Reina"},
    { label:"Agrupación de Artistas y Artesanos Paseo Arte La Reina"},
    { label:"Agrupación de Creadores y Artesanos de La Reina"},
    { label:"Agrupación de Emprendedoras Mujeres de Esfuerzo"},
    { label:"Agrupación de Emprendedores con Alma de Barrio"},
    { label:"Agrupación de Emprendedores Crear es Crecer"},
    { label:"Agrupación de Emprendedores Efuvare"},
    { label:"Agrupación de Emprendedores La Reina"},
    { label:"Agrupación de Emprendedores Las Pulguitas de Clorinda"},
    { label:"Agrupación de Emprendedores Rayun"},
    { label:"Agrupación de Emprendedores Talinay"},
    { label:"Agrupación de Emprendedores Unidos de La Reina"},
    { label:"Agrupación de Emprendedores Unidos por un Sueño"},
    { label:"Agrupación de Emprendedores Villa Tobalaba"},
    { label:"Agrupación la Magia del Moisaco"},
    { label:"Agrupación las Bellas Artesanas de La Reina"},
    { label:"Agrupación Mujeres Emprendedoras de La Reina - Meder"},
    { label:"Agrupacion Reininas Emprendedoras (ARE)"},
    { label:"Empresarias Creativas del Valle de La Reina"},
    { label:"Mujer Emprende la Reina"},
    { label:"Mujeres Creadoras"},
    { label:"Mujeres Emprendedoras"},
    { label:"Mujeres Empresarias de La Reina"},
    { label:"Mujeres Hoy" },
    { label: "Otra" }
  ];
  options_location_mensuales = [
    {
      label: "Plaza Clorinda Henríquez",
      aclaratoria: "Situada en la intersección de Clorinda Henríquez con Loreley",
      tipo: "Emprendedores en general"
    },
    {
      label: "Plaza La Reina",
      aclaratoria: "Ubicada en la intersección de Carlos Silva Vildosola con Nicanor Plaza",
      tipo: "Emprendedores en general"
    },
    {
      label: "Plaza Ossandón",
      aclaratoria: "Ubicada en la intersección de Carlos Ossandón con Echeñique",
      tipo: "Emprendedores en general"
    },
    {
      label: "Plaza de Arrieta",
      aclaratoria: "Ubicada en intersección de Diputada Laura Rodríguez con José Arrieta",
      tipo: "Emprendedores en general"
    },
    {
      label: "Talinay",
      aclaratoria: "Situada en la intersección de Av. Las Perdices con Parinacota",
      tipo: "Emprendedores en general"
    },
    {
      label: "Fernando Castillo Velasco",
      aclaratoria: "Emplazada desde Parinacota hasta Quinchamalí",
      tipo: "Emprendedores en general"
    },
    {
      label: "Pepe Vila",
      aclaratoria: "Ubicada en Pasaje Pepe Vila con Pepe Vila 580, La Reina",
      tipo: "Emprendedores en general"
    }
  ];
  /*
  options_location = [
    {
      label:"Plaza de Arrieta (Dip. Laura Rodríguez / J. Arrieta)",
      mounting: "Desde el Jueves 14 y Viernes 15 durante la mañana.",
      start_time: "17 a 24 hrs.",
    },
    {
      label: "Talinay",
      mounting: "Desde el Jueves 14 y Viernes 15 durante la mañana.",
      start_time: "17 a 24 hrs.",
    },
    {
      label: "Fernando Castillo Velasco",
      mounting: "Desde el Jueves 14 y Viernes 15 durante la mañana.",
      start_time: "10 a 22 hrs."
    },
    { 
      label: "Clorinda Henríquez",
      mounting: "Jueves 14",
      start_time: "10 a 22 hrs."
    },
    {
      label: "Plaza Chile-Perú",
      mounting: "Jueves 14",
      start_time: "15 a 23 hrs.",
    },
    {
      label: "Plaza Las Campanas",
      mounting: "Jueves 14",
      start_time: "10 a 22 hrs."
    },
    {
      label: "Plaza Ossandon",
      mounting: "Jueves 14",
      start_time: "10 a 22 hrs."
    },
    {
      label: "Plaza La Reina",
      mounting: "Jueves 14",
      start_time: "10 a 22 hrs."
    },
    {
      label: "Pepe Vila",
      mounting: "Jueves 14",
      start_time: "10 a 22 hrs."
    }
  ]
  */


  private formHasSelection(value: agrupacionObj) {
    if (value.label != "")
      return true
    return false
  }
  
  private chilean: boolean = false;
  
  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }
  private onVerify(response: any) {
    this.sendTicketAttemp();
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }
  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
      if (this.formData.district) {
        this.options_district.unshift(this.formData.district);
      }
    });
  }

  private formatRutSubrogante() {
    if (this.formData.subrogante.document_id != "1") {
      return true;
    }
    this.formData.subrogante.document_id = this.formData.subrogante.document_id
      .replace(/[.-]/g, "")
      .replace(/[ ]*/g, "")
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  private mounted() {
    /*
    (window as any).grecaptcha.render("grecaptcha", {
      sitekey: "6LfgYMgUAAAAAEhThu7w7hH2oyuo4eKhjfsmNPua"
    });
    */
    switch (this.$route.query.tipo) {
      case "mensuales":
      case "navidad":
        this.form_type = 3
        break;
      default:
        this.form_type = 0
        break
    }
  }

  private options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  private getServiceNumber() {
    // @todo - obtener parámetros desde la api
    switch (this.$route.query.tipo) {
      case "navidad":
        return 215;
      case "emprendedor":
        return 197;
      case "artesano":
      default:
        return 198;
    }
  }
  private getTitleDescription() {
    switch (this.$route.query.tipo) {
      case "navidad":
        return "Navidad";
      case "emprendedor":
        return "Emprendimiento";
      case "artesano":
      default:
        return "Artesanos";
    }
  }

  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_cliente) {
      this.formData.street = "";
    }
  }

  private getTicketMessage() {
    let ticketMessage = "Datos del postulante: \n";
    // determinar si existe agrupación
    if (this.formData.agrupacion && this.formData.agrupacion.label == 'Otra' && this.formData.nueva_agrupacion)
      ticketMessage = ticketMessage.concat("Agrupación: ".concat(this.formData.nueva_agrupacion));
    else if (this.formData.agrupacion && this.formData.agrupacion.label != "")
      ticketMessage = ticketMessage.concat("Agrupación: ".concat(this.formData.agrupacion.label));
    else
      ticketMessage = "Agrupación:";
    if (this.formData.nombre_fantasia && this.formData.nombre_fantasia != "")
      ticketMessage = ticketMessage.concat(";Nombre fantasía: ".concat(this.formData.nombre_fantasia));
    else
      ticketMessage= ticketMessage.concat(";Nombre fantasía:");
    if (this.formData.rubro && this.formData.rubro != "")
      ticketMessage = ticketMessage.concat(";Rubro: ".concat(this.formData.rubro));
    else 
      ticketMessage = ticketMessage.concat(";Rubro:");
    if (this.formData.instagram && this.formData.instagram !="")
      ticketMessage = ticketMessage.concat(";Instagram: ".concat(this.formData.instagram));
    else
      ticketMessage = ticketMessage.concat(";Instagram:");
    // determinar si se ingresó subrogante
    let subrogante = null;
    delete this.formData.subrogante.document_type;
    if (this.form_type == 3) // agrega campo
      ticketMessage = ticketMessage + ";Ubicación a la que postula: " + this.formData.ubicacion.label
    else if (Object.keys(this.formData.subrogante).length) {
      subrogante = Object.values(this.formData.subrogante).join(" "); 
      ticketMessage = ticketMessage + ";Persona subrogante: " + subrogante;
    }
    else 
      ticketMessage = ticketMessage.concat(";Persona subrogante:");
    ticketMessage = ticketMessage + ";Descripción venta: " + this.formData.message;
    return ticketMessage;
  }

  private notifyError(message:string) {
    this.$q.notify({
      message: message,
      color: "accent",
      position: "top",
      timeout: 7500
    });
    this.disable_button = false;
  }

  sendTicketAttemp() {
    this.disable_button = true;
    if (!this.formData.acepta_condiciones) {
      this.notifyError("Debe aceptar las condiciones para continuar");
      return;
    }
    if (!this.areAllFilesOK()) {
      this.notifyError(this.$t("notification.problem_with_files") as string);
      return;
    }
    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      if (this.form_type == 3)
        return this.notifyError('Debe adjuntar una única foto por producto en el formulario de postulación.')
      adjuntos = null;
    }

    // enviar post al backend.
    this.$axios
      .post(
        "tickets/solicitud/feriaEmprendimiento",
        {
          ticket: {
            id_servicio: this.getServiceNumber(),
            mensaje: this.getTicketMessage(),
            origen: 4,
            tipo: 0,
            direccion: {
              id_comuna: 108,
              calle: "Alcalde Fernando Castillo Velasco",
              numero: 9954,
              aclaratoria: ""
            }
          },
          direccion: {
            id_comuna: this.formData.district.value,
            calle: this.formData.street,
            numero: this.formData.street_number,
            aclaratoria: ""
          },
          dispositivo: navigator.userAgent,
          captcha: captcha,
          persona: {
            rut: this.formData.document_id,
            nombre: this.formData.name,
            apellido_paterno: this.formData.father_last_name,
            apellido_materno: this.formData.mother_last_name,
            email: this.formData.email,
            telefono: this.formData.phone,
            tipo_documento: 1,
            id_pais: 47
          },
          adjunto: adjuntos
        },
        {
          headers: {
            Authorization: this.$store.state.user.auth_token
              ? this.$store.state.user.auth_token
              : null,
            Captcha: captcha
          }
        }
      )
      .then(response => {
        let ticket = {
          id: response.data.id_ticket,
          guest_id: this.formData.document_id,
          guest_name: this.formData.name,
          guest_lastname: this.formData.father_last_name,
          guest_mother_lastname: this.formData.mother_last_name,
          message: response.data.mensaje,
          date: response.data.fecha,
          service_name: response.data.service_name,
          step: 4,
          adjuntos: response.data.adjunto ? response.data.adjunto : null,
          tipo: 0
        };
        this.$store.dispatch("submitFinishedGuestRequest", ticket);
      })
      .catch(error => {
        return error;
      });
  }

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };
}
